.audio-container {
    position:fixed;
    bottom:0px;
    width:100%;
    height:120px;
    background-image:url("../images/bgaudio-art.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color:#20231e;
    display: flex;
    flex-direction: column;
}

.audio-time {
    padding:0px 10px 0px 0px;
    position: absolute;
    top: -14px;
    left: 0px;
    right: 0px;
}

.audio-player{
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
    height:85px;
}  

.audio-photo{
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
    top:0px;
} 

.audio-controls {
    width:100%;
    height:100%;
    display: flex;
    flex-direction: row;
}

.audio-photo div.audio-action {
    padding: 0px 0px 0px 10px;
}

.audio-player div.audio-action {
    margin-top:-10px;
    padding: 0px 0px 0px 0px;
}
.audio-action {
    width: 15%; 
    height:100%;
    align-items: center;
    justify-content: center;
    display:flex;
}

.audio-photo div.audio-desc {
    margin-top: 0px;
}

.audio-player div.audio-desc {
    margin-top: -6px;
}

.audio-desc {
    width: 82%; 
    height:100%;
    padding: 0px 0px 10px 10px;
    color:#FFFFFF;
    font-size:15px;
}

/*.infoVTT {
    vertical-align: middle;
    line-height: 85px;
}*/

.vtt-zone {
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    height:100%;
}
.audio-pin {
    width: 8%; 
    height:100%;
    padding: 5px 20px 0px 0px;
    align-items: center;
    justify-content: center;
    text-align: right;
    margin-right: 5px;
}

.animate-spin {
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}