body {
  /*background-color:#2f322d;*/
  background-color:#FFFFFF;
}

.bgespritlalique {
  background-image: url("../images/bgapp.png");
  background-repeat: no-repeat;
  background-size: cover;
  position:fixed;
  z-index:2;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
}

.App {
z-index:10;
 position:absolute;
 top:0px;
 left:0px;
 bottom:0px;
 right:0px;
}


.App-header {
  background-color: #2f322d;
  height:250px;
  display: flex;
  right:0px;
  left:0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position:fixed;
  z-index:1000;
}

.App-link {
  color: #61dafb;
}

.appButtons {
  background-color:#000000 !important;
  border:#FFFFFF solid 2px !important; 
  position: fixed !important;
  top: 10px;
  left:10px;
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.modal-infobackdrop {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  bottom:120px;
  width: 100%;
}

.infosidebar {
  background: #edf1f3;
  position: absolute;
  right: 0px;
  top:0px;
  left:0px;
  bottom:0px;
  overflow: hidden;
}

.fullsidebar {
  background: #edf1f3;
  position: absolute;
  right: 0px;
  top:0px;
  left:0px;
  bottom:0px;
}

.fulltransparentsidebar {
  background: transparent;
  position: absolute;
  right: 0px;
  top:0px;
  left:0px;
  bottom:0px;
}
.sidebar {
  background: #edf1f3;
  position: absolute;
  width:78%;
  top:0px;
  left:0px;
  bottom:0px;
}

.infodetails {
  position: absolute;
  top:0px;
  left:0px;
  bottom:0px;
  right:0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}


.infoTexte {
  padding: 10px 15px;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
  justify-content: center;
  /*font-size:18px;*/
}

.infoTexte > p {
  margin: 0px 0px; 
  padding: 5px 0px; 
  margin-block-end: 0px; 
  margin-block-start: 0px;
}
.imgslide {
  width:100%;
  height:auto;
  max-height:300px;
}
.slideindicator {
  width: 100%;
  margin-top: 0px;
  text-align: center;
  z-index: 100;
  position: absolute;
  bottom: 0px;
}

.portrait-primary {
  display:none;
}
.portrait-primary > div {
  display:none;
}

.landscape-primary, .landscape-secondary {
  z-index: 20000;
  position: fixed;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  overflow: hidden;
  background-color:#2f322d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infolandscape{
  width:75%;
  margin:0 auto;
  color:#FFFFFF;
  text-align: center;
  font-size: 20px;
}

.landscapeimg {
  width:200px;
  height:auto;
}

.optionszone {
  margin-top:80px;
  padding:10px 10px;
  width:100%;
}

.centertext {
  text-align:center;
  margin-bottom:8px;
} 
.tooltiptext {
  display: flex;
  flex-direction: column;
  text-align:center;
  font-size:16px;
}

.tooltiptextleft {
  display: flex;
  flex-direction: column;
  text-align:left;
  font-size:16px;
}
.helpnext {
  position: fixed;
  bottom: 15px; 
}
.infoTextePhoto {
  padding: 10px 15px;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height:100vh;
  text-align: center;
}

.helperzone {
  position:absolute;
  top:0px;bottom:0px;left:0px;right:0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zonehelpbutton {
  background-color:transparent;
  width:56px;
  height:56px;
  position:absolute;
}

.mouse {
  width: 45px;
  height: 90px;
  border-radius: 20px;
  border: 3px solid rgba(255, 255, 255, 0.8);
  position: absolute;
  top: calc(50% - 120px);
}
.mouse:before, .mouse:after {
  content: "";
  display: block;
  position: absolute;
}

/* First */
.m-0:before {
  height: 70px;
  width: 4px;
  top: 10px;
  left: calc(50% - 2px);
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
}
.m-0:after {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: 15px;
  left: calc(50% - 8px);
  background: rgba(255, 255, 255, 1);
  -webkit-animation: m-0-ball 1.2s ease-in-out infinite;
          animation: m-0-ball 1.2s ease-in-out infinite;
}

@-webkit-keyframes m-0-ball {
  40%, 60% {
    transform: translateY(40px);
  }
}

@keyframes m-0-ball {
  40%, 60% {
    transform: translateY(40px);
  }
}