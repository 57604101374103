
.gallery {
	position: absolute;
    width: 100%;
	height: 100vh;
	top:0px !important;
    left:0px;
    right:0px;
    bottom:120px;
    background-color:transparent;
    color: #949494;
    overflow:hidden;
    visibility:hidden;
}

.cards {
	position: absolute;
    width: 200px;
	height: 200px;
	top: 43%;
	left: 50%;
	transform: translate(-43%, -60%);
	margin: 0px;
	padding: 0px;
}

.cards li img {
	width: 200px;
	height: 200px;
}

.cards li img.overimg {
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%235C5F5BFF' stroke-width='10' stroke-dasharray='96%25%2c 5%25' stroke-dashoffset='86' stroke-linecap='butt'/%3e%3c/svg%3e");
	width: 202px;
height: 202px;
border-radius: 100px;
position: absolute;
top: -1px;
left: -1px;
}

.cards li {
	list-style: none;
	padding: 0;
	margin: 0;
	width: 200px;
	height: 200px;
	text-align: center;
	line-height: 200px;
	font-size: 4rem;
	font-family: sans-serif;
	background-color: transparent;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 100px;
	overflow:hidden;
}