/* =========================
Custom Input range 
=============================*/

/* Input range - chrome and safari */

.progress {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  
  /* time */
  .time {
    color: #ffffff;
    font-size: 13px;
    line-height: 46px;
  }
  
  .time.current {
    color: #ffffff;
  }

input[type="range"] {
    --range-progress: 0;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    background:#3e3f3d;
    width: 100%;
    height: 18px;
    cursor: pointer;
  }
  
  /* Input range - firefox */
  input[type="range"]::-moz-range-track {
    position: relative;
    background:#3e3f3d;
    width: 100%;
    height: 18px;
    cursor: pointer;
  }
  
  /* played progress length - Chrome & safari*/
  input[type="range"]::before {
    content: '';
    height: 18px;
    background: #787a77;
    width: var(--range-progress);
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  /* played progress length - firefox */
  input[type="range"]::-moz-range-progress {
    background: #787a77;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    height: 18px;
  }
  
  /* slider thumb - chrome and safari */
  /*input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: none;
    background-color: #f50;
    cursor: pointer;
    position: relative;
  }*/
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: 8px; /* Centers thumb on the track */
    background-color: #c3c3c3;
    height: 30px;
    width: 15px;    
    transform: scale(1.1);
 }

  /* dragging thumb - chrome and safari */
  input[type="range"]:active::-webkit-slider-thumb {
    transform: scale(1.3);
  }
  
  /* slider thumb - firefox */
  input[type="range"]::-moz-range-thumb {
    height: 30px;
    width: 12px;
    margin-top: 8px;
    background: #c3c3c3;
    cursor: pointer;
    border: transparent;
    position: relative;
    transform: scale(1.1);
  }
  /* dragging thumb - firefox */
  input[type="range"]:active::-moz-range-thumb {
    transform: scale(1.2);
  }